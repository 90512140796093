<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      Accommodation Form
    </template>
    <template slot="body">
      <div class="card">
        <div class="card-body">
          <h3>Accomodation Details</h3>

          <FormInput
            v-model="result.name"
            label="Accommodation Name"
          />
          <FormSelect
            v-model="result.city_id"
            :options="cities.data"
            label="Accommodation Location"
          />
          <!-- <FormInput
            v-model="result.address"
            label="Accommodation Address"
          /> -->
          <FormSelect
            v-model="result.accommodation_type_id"
            label="Accommodation Type"
            :options="accommodationTypes"
          />
        </div>

        <div class="card mt-3">
          <div class="card-body">
            <h4>Accommodation Rooms</h4>
            <ul
              v-if="result.rooms.length"
              class="list-group list-group-flush"
            >
              <li
                v-for="(room, i) in result.rooms"
                :key="room.key"
                class="list-group-item px-0 py-4"
              >
                <div class="row">
                  <div class="col-12">
                    <FormSelect
                      v-model="room.accommodation_bathroom_type_id"
                      :options="bathroomTypes"
                      label="Bathroom Type"
                    />
                  </div>
                  <div class="col-md">
                    <FormInput
                      v-model="room.max_people"
                      type-number
                      label="Max. People"
                    />
                  </div>
                  <div class="col-md">
                    <FormInput
                      v-model="room.price_per_week"
                      type-number
                      label="Price Per Week"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <FormCheckbox
                      :value="room.show_special_price"
                      label="Has Special Price"
                      @input="setSpecialPrice($event, i)"
                    />
                  </div>
                </div>
                <div
                  v-if="room.show_special_price"
                  class="row mt-2"
                >
                  <div class="col">
                    <FormDate
                      v-model="room.special_price.from"
                      label="Date from"
                    />
                  </div>
                  <div class="col">
                    <FormDate
                      v-model="room.special_price.to"
                      label="Date To"
                    />
                  </div>
                  <div class="col">
                    <FormInput
                      v-model="room.special_price.price"
                      type-number
                      label="Price per Week"
                    />
                  </div>
                </div>

                <div
                  class="mt-2 btn btn-outline-danger btn-sm btn-block"
                  @click="deleteRoom(i)"
                >
                  <i class="fas fa-times mr-2" /> Delete Room
                </div>
              </li>
            </ul>

            <p
              v-else
              class="text-center py-3 bg-light"
            >
              <small>
                This Accommodation has no Rooms, please add a new Room.
              </small>
            </p>
            <div
              class="btn btn-sm btn-outline-primary btn-block"
              @click="addRoom"
            >
              <i class="fas fa-plus mr-2" />
              Add New Room
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div
        class="btn btn-sm btn-outline-success"
        @click="save"
      >
        <i class="fas fa-save mr-2" />
        Save
      </div>
    </template>
  </vue-modal>
</template>
<script>
/* eslint-disable no-restricted-syntax */
import { mapActions, mapGetters } from 'vuex';
import {
  FormInput, FormSelect, FormDate, FormCheckbox,
} from '@/components/inputs';

export default {
  components: {
    FormInput,
    FormSelect,
    FormDate,
    FormCheckbox,
  },
  props: {
    accommodation: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    result: {
      name: null,
      city_id: null,
      address: null,
      accommodation_type_id: null,
      rooms: [
        {
          key: Math.random() * Date.now(),
          name: null,
          max_people: null,
          price_per_week: null,
          accommodation_bathroom_type_id: null,
          special_price: null,
          show_special_price: false,
        },
      ],
    },
  }),
  computed: {
    ...mapGetters([
      'accommodationTypes',
      'bathroomTypes',
      'cities',
    ]),
  },
  mounted() {
    if (this.accommodation) {
      this.result = { ...this.accommodation };
    }
    this.getCities({ per_page: 1000 });
  },
  methods: {
    ...mapActions([
      'updateAccommodation',
      'createAccommodation',
      'getCities',
    ]),
    close() {
      this.$refs.modal.close();
    },
    addRoom() {
      this.result.rooms.push({
        key: Math.random() * Date.now(),
        name: null,
        max_people: null,
        price_per_week: null,
        accommodation_bathroom_type_id: null,
        show_special_price: false,
      });
    },
    deleteRoom(i) {
      this.result.rooms.splice(i, 1);
    },
    validate() {
      let valid = true;

      if (!this.result.name) {
        valid = false;
        this.$noty.error('Accommodation Name is required');
      }
      if (!this.result.city_id) {
        valid = false;
        this.$noty.error('Accommodation Location is required');
      }
      if (!this.result.accommodation_type_id) {
        valid = false;
        this.$noty.error('Accommodation Type is required');
      }
      if (!this.result.rooms.length) {
        valid = false;
        this.$noty.error('Each Accommodation must have at least 1 room');
      } else {
        let i = 1;
        for (const room of this.result.rooms) {
          if (!room.max_people) {
            valid = false;
            this.$noty.error(`Room #${i} Max people attribute is required`);
          }
          if (!room.price_per_week) {
            valid = false;
            this.$noty.error(`Room #${i} Price per week attribute is required`);
          }
          if (!room.accommodation_bathroom_type_id) {
            valid = false;
            this.$noty.error(`Room #${i} Bathroom Type attribute is required`);
          }
          if (room.show_special_price) {
            if (!room.special_price.to) {
              valid = false;
              this.$noty.error(`Room #${i} Special Price: To attribute is required`);
            }
            if (!room.special_price.from) {
              valid = false;
              this.$noty.error(`Room #${i} Special Price: From attribute is required`);
            }
            if (!room.special_price.price) {
              valid = false;
              this.$noty.error(`Room #${i} Special Price: Price attribute is required`);
            }
          }
          i += 1;
        }
      }
      return valid;
    },
    save() {
      if (this.validate()) {
        if (!this.accommodation.id) {
          this.createAccommodation(this.result)
            .then(() => {
              this.$noty.success('Accommodation Created succesfully');
              this.$emit('onSaved');
              this.close();
            });
        } else {
          this.updateAccommodation(this.result)
            .then(() => {
              this.$noty.success('Accommodation Created succesfully');
              this.$emit('onSaved');
              this.close();
            });
        }
      }
    },
    setSpecialPrice(showing, index) {
      this.result.rooms[index].show_special_price = showing;
      this.result.rooms[index].special_price = showing ? {
        from: null,
        to: null,
        price: null,
      } : null;
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss">
.list-group-item {
  border-bottom-width: 3px !important;

  &:last-child {
    border-bottom-width: 0 !important;
  }
}
</style>
