<template>
  <div>
    <h4>Suppliers</h4>

    <div class="card mt-4">
      <div class="card-header">
        <h4>Details</h4>
      </div>
      <div class="card-body">
        <FormInput
          v-model="form.name"
          label="Supplier Name"
        />
        <FormInput
          v-model="form.transfer_fee"
          type-number
          label="Transfer Fee"
        />
        <div
          class="btn btn-success btn-sm btn-block mt-3"
          @click="sendForm"
        >
          <i class="fas fa-save mr-3" />
          Save
        </div>
      </div>
    </div>

    <Accommodations
      v-if="form.id"
      :supplier-id="form.id"
    />
    <div
      v-else
      class="card mt-4"
    >
      <div class="card-header">
        <h4>Accommodations</h4>
      </div>
      <div class="card-body">
        <small>Save the supplier details to be able to create accommodations.</small>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FormInput } from '@/components/inputs';
import Accommodations from './accommodations/accommodations.vue';

export default {
  components: {
    FormInput,
    Accommodations,
  },
  data: () => ({
    form: {},
  }),
  computed: {
    ...mapGetters([
      'supplier',
    ]),
  },
  async mounted() {
    if (this.$route.name === 'supplierDetails') {
      await this.getSupplier(this.$route.params.id);

      this.form = this.supplier;
    }
  },
  methods: {
    ...mapActions([
      'getSupplier',
      'createSupplier',
      'updateSupplier',
    ]),
    sendForm() {
      if (this.form.id) {
        this.updateSupplier(this.form)
          .then(() => {
            this.$noty.success('Supplier updated succesfully');
          })
          .catch(this.showError);
      } else {
        this.createSupplier(this.form)
          .then(({ data }) => {
            this.form = {};
            this.$noty.success('Supplier created successfully');
            this.$router.push({ name: 'supplierDetails', params: { id: data.data.id } });
          })
          .catch(this.showError);
      }
    },
  },
};
</script>

<style>

</style>
