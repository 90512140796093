<template>
  <div>
    <div class="card mt-4 ">
      <div class="card-header">
        <div
          class="btn btn-outline-primary float-right btn-sm"
          @click="newAccommodation"
        >
          <i class="fas fa-plus mr-2" />
          New Accommodation
        </div>
        <h4>Accommodations</h4>
      </div>
      <div class="card-body">
        <vue-table
          v-if="vTable.headers"
          ref="vtable"
          :values="accommodations"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :options="vTable.options"
          @changed="getData"
          @onPressDelete="onPressDelete"
          @onPressEdit="onPressEdit"
        />
      </div>
    </div>

    <accommodations-form
      v-if="selAccommodation"
      :accommodation="selAccommodation"
      @close="selAccommodation=null"
      @onSaved="resetTable"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import accommodationsForm from './accommodationsForm.vue';

export default {
  components: {
    accommodationsForm,
  },
  props: {
    supplierId: {
      type: Number,
      default: null,
      required: true,
    },
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: 'name',
          sortable: true,
        },
        {
          title: 'city.name',
          mask: 'location',
          sortable: true,
        },
        {
          title: 'rooms_qty',
          mask: 'rooms',
        },
        {
          title: 'type_name',
          mask: 'type',
        },
      ],
      actions: [
        {
          buttonClass: 'btn-info btn-sm', // use any class you want
          callback: 'onPressEdit', // action you have to subscribe in the vue-table element
          tooltip: 'Edit', // tooltip on hover
          icon: 'fas fa-edit', // You can use any icon package, just install it before
        },
        {
          buttonClass: 'btn-danger btn-sm', // use any class you want
          callback: 'onPressDelete', // action you have to subscribe in the vue-table element
          tooltip: 'Delete', // tooltip on hover
          icon: 'fas fa-times', // You can use any icon package, just install it before
        },
      ],
      options: {
        tableClass: 'table table-striped',
      },
    },
    selAccommodation: null,
  }),
  computed: {
    ...mapGetters([
      'accommodations',
    ]),
  },
  mounted() {
    console.log(this.supplierId);
    this.$refs.vtable.init();
    this.getAccommodationTypes();
    this.getBathroomTypes();
  },
  methods: {
    ...mapActions([
      'getAccommodations',
      'getAccommodationTypes',
      'getBathroomTypes',
      'deleteAccommodation',
    ]),
    getData(evt = {}) {
      console.log({
        ...evt,
        suppler_id: this.supplierId,
      });
      this.getAccommodations({
        ...evt,
        supplier_id: this.supplierId,
      }).catch(this.showError);
    },
    newAccommodation() {
      this.selAccommodation = {
        name: null,
        location: null,
        address: null,
        accommodation_type_id: null,
        supplier_id: this.supplierId,
        rooms: [
          {
            key: Math.random() * Date.now(),
            name: null,
            max_people: null,
            price_per_week: null,
            accommodation_bathroom_type_id: null,
          },
        ],
      };
    },
    onPressDelete(item) {
      this.fireConfirm().then(() => {
        this.deleteAccommodation(item)
          .then(() => {
            this.$noty.success('Accommodation Deleted Succesfully');
            this.resetTable();
          })
          .catch(this.showError);
      });
    },
    onPressEdit(item) {
      this.selAccommodation = item;
    },
    resetTable() {
      this.$refs.vtable.init();
    },
  },
};
</script>

<style>

</style>
